import { Field, Checkbox, Description, Label } from '@headlessuiv2/react'
import { CheckIcon, MinusIcon } from '@heroicons/react/24/solid'
import classnames from 'classnames'
import type { CheckboxProps } from '@headlessuiv2/react'
import type { ReactNode } from 'react'

type Props = {
  name: string
  label?: string | ReactNode
  description?: string
} & CheckboxProps

// TODO: Style the description
export default function ({ label, description, disabled, ...checkboxProps }: Props) {
  return (
    <Field className="group flex w-full cursor-pointer flex-col gap-x-2 data-[disabled]:cursor-not-allowed" disabled={disabled}>
      <div className="group/clickable flex select-none flex-row items-center gap-x-2">
        <Checkbox
          className={classnames(
            'group flex size-6 shrink-0 cursor-pointer items-center justify-center rounded-full text-brand-darkest outline-none',
            'group-hover/clickable:bg-brand-light',
            'group-data-[disabled]:cursor-not-allowed group-data-[disabled]:!bg-transparent',
            'transition-[opacity,color,background-color,border-color,text-decoration-color,fill,stroke] duration-100 ease-in-out'
          )}
          {...checkboxProps}>
          {({ checked, indeterminate }) => (
            <div
              className={classnames('flex size-4 items-center justify-center rounded border border-brand-medium', {
                'bg-white': !disabled,
                'bg-brand-brand-light': disabled
              })}>
              {checked && <CheckIcon className="size-3" />}
              {!checked && indeterminate && <MinusIcon className="size-3" />}
            </div>
          )}
        </Checkbox>

        {label && <Label className="w-full cursor-[inherit] overflow-hidden">{label}</Label>}
      </div>

      {description && <Description className="ml-8 cursor-default text-brand-dark">{description}</Description>}
    </Field>
  )
}
